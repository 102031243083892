@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "./styles/font/font.css";
@import "./styles/_typography.css";

* {
  font-family: "Pretendard";
}

body {
  overflow-x: hidden;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

html.dark {
  background-color: #121212 !important;
  /* @apply transition duration-1000 ease-out; */
}

html.light {
  /* @apply transition duration-1000 ease-out; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.each-slide-effect > div {
  display: flex;
  align-items: start;
  justify-content: center;
  background-size: cover;
  height: 208px;
  border-radius: 12px;
  padding-left: 60px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

label {
  outline: none !important;
}

.stepperSubDiv {
  margin: 1.4em;
  background: #172539;
  border-radius: 12px;
  padding: 1.4em;
  color: #63b38f;
}

.stepperSubDiv h1 {
  font-weight: 500;
  text-align: center;
  color: #70cea2;
}

.stepperBtn {
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background: #172539;
  color: #63b38f;
  border-radius: 12px;
  padding: 0.5em 1.4em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 1.4em;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
}

.pagination-btn {
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  color: #333;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.pagination-btn:hover {
  background-color: #e2e6ea;
}

.pagination-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-btn.active {
  background-color: #007bff;
  color: #ffffff;
  border-color: #007bff;
}

.nav-shwdow a {
  transition: 0.35s;
}

.nav-shwdow a:hover {
  text-shadow: 0 0 15px #42bc7f;
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

/* 입력 필드 스타일링 */
.location-search-input {
  width: 100%;
  /* padding: 20px; */
  padding-bottom: 4px;
  margin: 8px 0;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(176, 190, 197);
  border-top: none;
  border-left: none;
  border-right: none;
  /* border-radius: 4px; */
  background-color: inherit;
  font-size: 14px;
  transition: border 0.3s ease;
}

.location-search-input:focus {
  border-color: #007bff;
  outline: none;
}

/* 자동완성 드롭다운 컨테이너 */
.autocomplete-dropdown-container {
  position: relative;
  z-index: 1000;
  /* border: 1px solid #ccc; */
  font-size: 14px;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(8, 7, 7, 0.2);
}

/* 자동완성 항목 */
.suggestion-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover,
.suggestion-item--active {
  background-color: #f4f4f4;
}

/* 로딩 텍스트 스타일링 */
.autocomplete-dropdown-container div {
  padding: 10px 20px;
}

/*google map 지도로 찾기*/

.gm-style-iw-chr {
  display: none !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  padding: 20px !important;
  display: none !important;
}

.gm-style-iw-tc::after {
  content: ""; /* 가상 요소에 콘텐츠가 필요하므로, 비어있는 것을 설정합니다. */
  display: block; /* 가상 요소를 블록 레벨 요소로 만들어줍니다. */
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(15px);
  display: none !important;
}

.gm-ui-hover-effect {
  opacity: 0 !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  min-width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 110%;
  /*margin-left: -60px;*/
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.div_container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.div_container > button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 2px #e4e4e4;
  background-color: white;
  cursor: pointer;
  transition: 1s;
}

.div_container > button:hover {
  background-color: black;
  color: white;
}

.div_paper {
  /*height: 842px;*/
  /*width: 100vw;*/
  /*margin: 30px;*/
  background-color: white;
  /* text-align: center; */
}

.div_paper > div {
  /*margin: 30px;*/
}

.page-break {
  page-break-after: always;
  clear: both;
  display: block;
}

h1.break {
  page-break-before: always;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 20px; /* 둥근 모서리 */
  padding: 10px 20px; /* 버튼 안쪽 여백 */
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  display: none; /* 체크박스 숨기기 */
}

.custom-checkbox label {
  cursor: pointer; /* 커서 모양 변경 */
}

.background-blur {
  @apply backdrop-blur-2xl;
}
