@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 100;
    src: url("./woff2/Pretendard-Thin.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 200;
    src: url("./woff2/Pretendard-ExtraLight.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    src: url("./woff2/Pretendard-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url("./woff2/Pretendard-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    src: url("./woff2/Pretendard-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    src: url("./woff2/Pretendard-SemiBold.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    src: url("./woff2/Pretendard-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    src: url("./woff2/Pretendard-ExtraBold.woff2") format("woff2");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 900;
    src: url("./woff2/Pretendard-Black.woff2") format("woff2");
}
