.title {
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}

.subtitle-1 {
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}

.subtitle-2 {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}

.subtitle-3 {
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}

.content {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
}

.label-1 {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.label-1-strong {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.label-1-weak {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.label-2 {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.label-2-strong {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.label-2-weak {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.profile-menu-text {
  font-size:  0.875rem !important;
  font-weight: 600;
  line-height: 150%;
}


.profile-menu-text-mobile {
  font-size: 8px !important;
  font-weight: 600;
  line-height: 150%;
}


.grade-text {
  color: #fff;
  font-size: 0.72rem !important;
  font-weight: 900 !important;
}

.upload-text {
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.location-info-text {
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.page-button-text {
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.filter-item-text {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.no-data-title {
  font-size: 1.375rem !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.cart-nickname {
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.my-fof-text {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.fof-text {
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.feed-title {
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.feed-geo {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.feed-price {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.locker-list-title {
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.locker-list-nickname {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.locker-list-type {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.report-checkbox {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 150% !important;

  color: #555;
}
